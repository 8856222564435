import React, { Dispatch, FC } from 'react'
import { ActionPayload, State } from '../types'
import { formatDate, formatTime } from '../utils/formatDate'
import { FullPageLayout } from '../components/FullPageLayout'
import Logo from '../assets/logo_il.png'
import begin from '../assets/poczatek.svg'
import { Checkout } from './Checkout'

type Props = {
  state: State
  dispatch: Dispatch<ActionPayload>
}

export const LoggedPage: FC<Props> = ({ state, dispatch }) => {
  const { group } = state
  return (
    <FullPageLayout>
      <section className='waiting'>
        <img src={Logo} alt='Instytut Łączności' className='waiting-logo' />
        <div className='waiting-box'>
          <div>
            <img src={begin} alt='' />
          </div>
          <header className='waiting-header'>
            <h1>{group!.name}</h1>
            <p>Jesteś zalogowany, ale niestety nie ma aktywnych głosowań.</p>
            <Checkout state={state} dispatch={dispatch} />
          </header>
        </div>
      </section>
    </FullPageLayout>
  )
}
