import { ErrorType } from '../types'

export function handleException(response: any | null = null): ErrorType {
  if (response == null) return { type: 'ERROR' }
  switch (response.status) {
    case 400:
      let msg = 'Błąd żądania.'
      if (response.data && response.data['code']) {
        msg += ' Twój kod został już wykorzystany.'
      }
      return {
        type: 'ERROR',
        msg: msg,
      }
    case 404:
      return {
        type: 'ERROR',
        msg: 'Sprawdź, czy połączenie z Internetem jest w porządku oraz czy jesteś podpięty do sieci wewnętrznej.',
      }
    case 403:
      return {
        type: 'ERROR',
        msg: 'Nie masz uprawnień by wiedzieć tę stronę.',
      }
    default:
      return { type: 'ERROR', msg: 'Błąd nieznany. Jeśli się powtarza skontaktuj się z pomocą techniczną.' }
  }
}
