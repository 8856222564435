import React, { Dispatch, FC } from 'react'
import { ActionPayload, State } from '../types'
import { InitPage } from './InitPage'
import { ErrorPage } from './ErrorPage'
import { FinishPage } from './FinishPage'
import { LoginPage } from './LoginPage'
import { FormPage } from './FormPage/FormPage'
import { WaitingPage } from './WaitingPage'
import { LoggedPage } from './LoggedPage'
import { ToastProvider } from 'react-toast-notifications'
import { ResultsLink } from '../components/ResultsLink'
import { ResultsPage } from './ResultsPage'
import { ResultsOldPage } from './ResultsOldPage'

type Props = {
  state: State
  dispatch: Dispatch<ActionPayload>
}

export const Pages: FC<Props> = ({ state, dispatch }) => (
  <main>
    <ToastProvider>
      {state.pageType === 'INIT' && <InitPage />}
      {state.pageType === 'ERROR' && <ErrorPage msg={state.errorMsg} />}
      {state.pageType === 'WAITING' && <WaitingPage state={state} />}
      {state.pageType === 'LOGGED' && <LoggedPage state={state} dispatch={dispatch} />}
      {state.pageType === 'LOGIN' && <LoginPage state={state} dispatch={dispatch} />}
      {state.pageType === 'FORM' && <FormPage state={state} dispatch={dispatch} />}
      {state.pageType === 'FINISH' && <FinishPage state={state} dispatch={dispatch} />}
      {state.pageType === 'RESULTS' && <ResultsPage state={state} dispatch={dispatch} />}
      {state.pageType === 'OLD_RESULTS' && <ResultsOldPage state={state} dispatch={dispatch} />}
      <ResultsLink dispatch={dispatch} pageType={state.pageType} />
    </ToastProvider>
  </main>
)
