import React, { FC } from 'react'

type Props = {
  type?: 'submit' | 'button'
  className?: string
  disabled?: boolean
  onClick?: () => void
}

export const Button: FC<Props> = ({ type = 'submit', className = '', children, disabled, onClick }) => (
  <div className='control'>
    <button
      type={type}
      className={`button is-primary ${className}`}
      disabled={disabled}
      onClick={() => {
        onClick && onClick()
      }}
    >
      {children}
    </button>
  </div>
)
