import React, { FC, Dispatch, FormEvent, useState } from 'react'
import { ActionPayload, State, VoteWithQuestions } from '../types'
import { GET_QUESTIONS } from '../urls'
import axios from 'axios'
import { Button } from '../components/Button'
import { Header } from '../components/Header'
import begin from '../assets/poczatek.svg'

type Props = {
  state: State
  dispatch: Dispatch<ActionPayload>
}

export const LoginPage: FC<Props> = ({ state, dispatch }) => {
  const [error, setError] = useState<String>()

  function submit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const code = new FormData(event.target as HTMLFormElement).get('code')
    if (code === '') {
      setError('To pole jest wymagane.')
      return
    }
    axios
      .post<VoteWithQuestions | ''>(GET_QUESTIONS, { code })
      .then(({ data }) => {
        dispatch({ type: 'LOGIN', payload: { voteWithQuestions: data, code: String(code) } })
      })
      .catch((reason) => {
        if (reason.response) {
          setError(reason.response.data.code ?? 'Nieznany błąd serwera. Skontaktuj się z pomocą techniczną.')
        } else {
          setError('Nieznany błąd. Skontaktuj się z pomocą techniczną.')
        }
      })
  }

  return (
    <>
      <div className='container-fluid'>
        <Header vote={state.group!} />
      </div>
      <div className='is-flex is-justify-content-center is-flex-direction-column is-align-items-center'>
        <section className='login'>
          <div className='login-box'>
            <div className='box-container'>
              <img src={begin} alt='' />
            </div>
            <form onSubmit={submit} noValidate={true}>
              <div className='field'>
                <label className='label' htmlFor='code'>
                  Wpisz kod dostępu
                </label>
                <div className='control'>
                  <input className='input' name='code' id='code' type='text' required={true} autoComplete='false' />
                </div>
                {error && <p className='help is-danger'>{error}</p>}
              </div>
              <Button>Wyślij</Button>
            </form>
          </div>
        </section>
      </div>
    </>
  )
}
