import React, { FC } from 'react'

type Props = {
  className?: string
}

export const HeroLayout: FC<Props> = ({ className, children }) => (
  <section className={`hero is-fullheight ${className || ''}`}>
    <div className='hero-body is-justify-content-center'>
      <div>{children}</div>
    </div>
  </section>
)
