import React, { Dispatch, FC } from 'react'
import { ActionPayload, State } from '../types'
import { formatDate, formatTime } from '../utils/formatDate'
import { FullPageLayout } from '../components/FullPageLayout'
import Logo from '../assets/logo_il.png'
import begin from '../assets/poczatek.svg'
import { Checkout } from './Checkout'

type Props = {
  state: State
}

export const WaitingPage: FC<Props> = ({ state }) => {
  const { group, server_date } = state
  return (
    <FullPageLayout>
      <section className='waiting'>
        <img src={Logo} alt='Instytut Łączności' className='waiting-logo' />
        <div className='waiting-box'>
          <div>
            <img src={begin} alt='' />
          </div>
          <header className='waiting-header'>
            <h1>{group!.name}</h1>
            <p>
              {group!.start_date > server_date && (
                <>
                  rozpocznie się:
                  <br />
                  {formatDate(group!.start_date)} o {formatTime(group!.start_date)}
                </>
              )}
              {group!.end_date < server_date && 'Głosowanie zakończone'}
            </p>
          </header>
        </div>
      </section>
    </FullPageLayout>
  )
}
