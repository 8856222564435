import React, { ChangeEvent, Dispatch, useEffect, useState } from 'react'
import { ActionPayload, Stat, State, VoteInfo } from '../types'
import axios from 'axios'
import { GET_STATS, GET_VOTE_GROUPS } from '../urls'
import { InitPage } from './InitPage'
import { Header } from '../components/Header'
import { Results } from './Results'

type Props = {
  state: State
  dispatch: Dispatch<ActionPayload>
}

export function ResultsOldPage({ state, dispatch }: Props) {
  const [{ stats, voteGroups, selected }, setState] = useState<{
    stats: Stat[] | null
    voteGroups: VoteInfo[] | null
    selected: VoteInfo | null
  }>({
    stats: null,
    voteGroups: null,
    selected: null,
  })

  useEffect(() => {
    axios.get<VoteInfo[]>(GET_VOTE_GROUPS).then(({ data }) => {
      setState((prev) => ({
        ...prev,
        voteGroups: data.map((x) => ({ ...x, start_date: new Date(x.start_date), end_date: new Date(x.end_date) })),
      }))
    })
  }, [])

  function changeVoteGroup(evt: ChangeEvent<HTMLSelectElement>) {
    const newValue = evt.target.value
    if (newValue === '') {
      setState((prev) => ({ ...prev, selected: null, stats: [] }))
    } else {
      axios.get<Stat[]>(GET_STATS + '/' + newValue).then(({ data }) =>
        setState((prev) => ({
          ...prev,
          stats: data,
          selected: prev.voteGroups!.find((x) => x.id === Number(newValue))!,
        }))
      )
    }
  }

  if (voteGroups == null) {
    return <InitPage />
  }

  return (
    <>
      <div className='container-fluid'>
        {selected != null ? (
          <Header vote={selected} />
        ) : (
          <Header vote={{ id: 0, name: 'Wybierz grupę na liście poniżej' }} />
        )}
      </div>
      <div className='is-flex is-justify-content-center is-flex-direction-column is-align-items-center'>
        {voteGroups.length === 0 ? (
          <div className='box'>Brak archiwalnych głosowań.</div>
        ) : (
          <section>
            <div className='field'>
              <label className='label' htmlFor='vote_group'>
                Wybierz grupę głosowań
              </label>
              <div className='control'>
                <div className='select'>
                  <select id='vote_group' onChange={changeVoteGroup}>
                    <option value=''>---</option>
                    {voteGroups.map(({ id, name }) => (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </section>
        )}
        <section className='results'>{stats && stats.length > 0 && <Results stats={stats} />}</section>
        <button
          className='button is-small is-primary is is-rounded mx-4 mt-4'
          onClick={() => {
            if (state.code) {
              dispatch({ type: 'LOGGED' })
            } else if ((state.group?.end_date || state.server_date) < state.server_date) {
              dispatch({ type: 'WAITING' })
            } else {
              dispatch({ type: 'TO_LOGIN' })
            }
          }}
        >
          Powrót
        </button>
      </div>
    </>
  )
}
