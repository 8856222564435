import React, { FC } from 'react'
import { HeroLayout } from '../components/HeroLayout'

type Props = {
  msg?: string
}

export const ErrorPage: FC<Props> = ({
  msg = 'Coś poszło nie tak. Odśwież stronę i jeśli błąd się powtarza zgłoś się do pomocy technicznej.',
}) => (
  <HeroLayout className='is-danger'>
    <p className='title'>{msg}</p>
  </HeroLayout>
)
