import React from 'react'
import { HeroLayout } from '../components/HeroLayout'

export const InitPage = () => (
  <HeroLayout>
    <p className='title'>
      <span className='is-sr-only'>Trwa ładowanie danych...</span>
      <progress className='progress is-primary' max='100' />
    </p>
  </HeroLayout>
)
