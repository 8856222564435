import React, { Dispatch, FC, Reducer, useReducer, useRef } from 'react'
import { ActionPayload, State } from '../../types'
import { FormAction, Section, State as LocalState } from './types'
import { Header } from '../../components/Header'
import axios from 'axios'
import { SEND_ANSWERS } from '../../urls'
import { Button } from '../../components/Button'
import { init, reducer } from './reducer'
import { FormSection } from './FormSection'
import { ModalContent } from './ModalContent'
import { handleException } from '../../utils/handlingExceptions'

type Props = {
  state: State
  dispatch: Dispatch<ActionPayload>
}

export const FormPage: FC<Props> = ({ state, dispatch }) => {
  const [localState, localDispatch] = useReducer<Reducer<LocalState, FormAction>>(reducer, init(state.questions!))
  const modalRef = useRef<HTMLDivElement | null>(null)

  function submit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const answers = state.questions!.map(({ id }, index) => ({
      question: id,
      selected: localState.sections[index].filter(({ selected }) => selected).map(({ value }) => value),
    }))
    const data = {
      vote: state.vote?.id,
      code: state.code,
      answers,
    }
    axios
      .post(SEND_ANSWERS, data)
      .then(() => {
        dispatch({ type: 'FINISH' })
      })
      .catch((reason) => {
        if (reason.response) {
          if (new Date(reason.response.headers.date) > state.server_date) {
            dispatch({ type: 'WAITING' })
          }
          dispatch(handleException(reason.response))
        } else {
          dispatch(handleException(reason.response))
        }
      })
  }

  return (
    <>
      <Header vote={state.vote!} />
      <section className='container'>
        <div className='columns m-5'>
          <div className='column'>
            <form onSubmit={submit} noValidate={true}>
              {state.questions!.map((question, index) => (
                <FormSection
                  key={index}
                  index={index}
                  dispatch={localDispatch}
                  options={localState.sections[index]}
                  question={question}
                />
              ))}
              <div className='modal' ref={modalRef}>
                <div className='modal-background' />
                <div className='modal-card'>
                  <header className='modal-card-head'>
                    <p className='modal-card-title'>Prześlij głosy</p>
                    <button
                      type='button'
                      className='delete'
                      aria-label='zamknij'
                      onClick={() => modalRef.current?.classList.remove('is-active')}
                    />
                  </header>
                  <section className='modal-card-body'>
                    <div className='content'>
                      <h1 className='is-size-4 has-text-centered'>{state.vote?.name}</h1>
                      <ModalContent sections={localState.sections} />
                    </div>
                  </section>
                  <footer className='modal-card-foot'>
                    <button className='button is-primary'>Wyślij</button>
                    <button
                      type='button'
                      className='button'
                      onClick={() => modalRef.current?.classList.remove('is-active')}
                    >
                      Anuluj
                    </button>
                  </footer>
                </div>
              </div>
              <Button
                type='button'
                onClick={() => {
                  modalRef.current?.classList.add('is-active')
                }}
                disabled={localState.sections.some(
                  (section: Section, index) =>
                    state.questions![index].max_selected! <
                    section.reduce((prev, curr) => prev + Number(curr.selected), 0)
                )}
              >
                Prześlij głosy
              </Button>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}
