import React, { FC } from 'react'
import { formatDate, formatDateTime, formatTime } from '../utils/formatDate'
import { VoteInfo } from '../types'
import logo from '../assets/logo_il.png'

type Props = {
  vote: VoteInfo | Pick<VoteInfo, 'name' | 'id'>
}

function isVoteInfo(obj: Props['vote']): obj is VoteInfo {
  return 'end_date' in obj
}

export const Header: FC<Props> = ({ vote }) => (
  <header className='header'>
    <img src={logo} alt='Instytut Łączności' className='logo is-block' />
    <div className='is-flex is-justify-content-center is-align-items-center is-flex-direction-column'>
      <h1 className='is-size-4 has-text-weight-bold'>{vote.name}</h1>
      {isVoteInfo(vote) ? (
        <footer className='is-size-5'>
          {vote.end_date < new Date() ? (
            <>
              {formatDateTime(vote.start_date)} - {formatDateTime(vote.end_date)}
            </>
          ) : (
            <>
              zakończy się {formatDate(vote.end_date)} o {formatTime(vote.end_date)}
            </>
          )}
        </footer>
      ) : (
        <footer className='is-size-5'>...</footer>
      )}
    </div>
    <div className='empty' />
  </header>
)
