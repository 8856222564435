import React, { FC } from 'react'

type Props = {
  title: string
}

type RegexMatch = [string, string, string]

const LINK_REGEX = /\[(.+)]\((\S+)\)/

export const NameParser: FC<Props> = ({ title }) => {
  let results = []
  let i = 0
  let k = title.slice(0).search(LINK_REGEX)
  let match: RegexMatch | null = null
  while (k !== -1) {
    results.push(title.slice(i, k))
    match = title.slice(i).match(LINK_REGEX) as RegexMatch
    results.push(
      <a href={match[2]} target='_blank'>
        {match[1]}
      </a>
    )
    i = i + k + match[0].length
    k = title.slice(i).search(LINK_REGEX)
  }
  results.push(title.slice(i))
  return (
    <>
      {results.map((x, idx) => (
        <React.Fragment key={idx}>{x}</React.Fragment>
      ))}
    </>
  )
}
