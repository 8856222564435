import React, { Dispatch, FC } from 'react'
import logo from '../assets/logo_il.png'
import vote from '../assets/głos.svg'
import { ActionPayload, State } from '../types'
import { Checkout } from './Checkout'

type Props = {
  state: State
  dispatch: Dispatch<ActionPayload>
}

export const FinishPage: FC<Props> = ({ state, dispatch }) => {
  return (
    <section className='finish is-flex is-flex-direction-column is-align-items-center is-justify-content-center'>
      <img src={logo} alt='Instytut Łączności' className='finish-logo' />
      <img src={vote} alt='' className='finish-box' />
      <header>
        <h1>Dziękujemy!</h1>
        <p>Oddałeś swój głos.</p>
        <Checkout state={state} dispatch={dispatch} />
      </header>
    </section>
  )
}
