import React from 'react'
import { NameParser } from './NameParser'
import { Stat } from '../types'

type Props = {
  stats: Stat[]
}

export function Results({ stats }: Props) {
  return (
    <>
      {stats.map((stat) => (
        <React.Fragment key={stat.number}>
          <div key={stat.number} className='results-question'>
            <header>
              <h1>
                Głosowanie nr {stat.number}: {stat.name}
              </h1>
            </header>
            {stat.questions == undefined && <>Głosowanie wciąż trwa. Liczba oddanych głosów to: {stat.count}</>}
            {stat.questions && stat.questions.length > 0 && (
              <article>
                {stat.questions.map((question, index) => (
                  <div key={index}>
                    <div className='table-container'>
                      <div className='table-caption'>
                        <NameParser title={question.title} />
                      </div>
                      <table className='table is-striped is-hoverable'>
                        <thead>
                          <tr>
                            <th>{question.type === 'persons' ? 'Kandydaci' : 'Opcje'}</th>
                            <th>Liczba głosów</th>
                          </tr>
                        </thead>
                        <tbody>
                          {question.options.map((option, o_index) => (
                            <tr key={o_index}>
                              <td>{option.title}</td>
                              <td className='has-text-right'>{option.votes}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      Liczba osób biorących udział w głosowaniu: {stat.count}
                    </div>
                  </div>
                ))}
              </article>
            )}
          </div>
          <hr style={{ width: '75%', backgroundColor: '#ddeeee' }} />
        </React.Fragment>
      ))}
    </>
  )
}
