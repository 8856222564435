import { FormAction, State } from './types'
import { Question } from '../../types'
import { Reducer } from 'react'

export const reducer: Reducer<State, FormAction> = (state, action) => {
  switch (action.type) {
    case 'CHANGE':
      const newSection = [...state.sections]
      const { sectionId, answerId, checked } = action.payload
      newSection[sectionId][answerId].selected = checked
      return { ...state, sections: newSection }
  }
  return state
}

export function init(questions: Question[]): State {
  return {
    errors: [],
    sections: questions.map(({ options }) => options.map((value) => ({ value: value, selected: false }))),
  }
}
