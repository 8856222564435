export function formatDate(date: Date): String {
  return date.toLocaleDateString('pl')
}

export function formatTime(date: Date): String {
  return date.toLocaleTimeString('pl').slice(0, -3)
}

export function formatDateTime(date: Date): String {
  return date.toLocaleString('pl').slice(0, -3)
}
