import './styles/main.scss'
import React, { Reducer, useEffect, useReducer } from 'react'
import axios from 'axios'
import { GET_INFO } from './urls'
import { initialState, reducer } from './reducer'
import { ActionPayload, State, VoteInfo } from './types'
import { Pages } from './views/Pages'
import { handleException } from './utils/handlingExceptions'

function App() {
  const [state, dispatch] = useReducer<Reducer<State, ActionPayload>>(reducer, initialState)

  useEffect(() => {
    axios
      .get<VoteInfo>(GET_INFO)
      .then(({ data, status, headers }) => {
        setTimeout(() => {
          if (status === 200) {
            dispatch({ type: 'INIT', payload: { ...data, server_date: new Date(headers.date) } })
          } else {
            dispatch({ type: 'ERROR', msg: 'Administratorze, uzupełnij bazę danych.' })
          }
        }, 1000)
      })
      .catch((err) => {
        // @ts-ignore
        dispatch(handleException(err.response))
      })
  }, [])

  return <Pages state={state} dispatch={dispatch} />
}

export default App
