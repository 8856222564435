import React, { FC } from 'react'
import { Section } from './types'

type Props = {
  sections: Section[]
}

export const ModalContent: FC<Props> = ({ sections }) => {
  const selected = sections.reduce((prev, curr) => prev.concat(curr), []).filter(({ selected }) => selected)
  if (selected.length === 0) {
    return <>Czy na pewno chcesz oddać pustą kartę do głosowania?</>
  }

  return (
    <>
      Czy na pewno chcesz oddać głosy na:
      <ul>
        {selected.map(({ value }, index) => (
          <li key={`${value}_${index}`}>{value}</li>
        ))}
      </ul>
    </>
  )
}
