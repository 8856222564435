import { Dispatch, FC, useState } from 'react'
import { ActionPayload, State, VoteWithQuestions } from '../types'
import useInterval from 'react-useinterval'
import axios from 'axios'
import { GET_QUESTIONS } from '../urls'
import { Options, useToasts } from 'react-toast-notifications'

type Props = {
  state: State
  dispatch: Dispatch<ActionPayload>
}

export const Checkout: FC<Props> = ({ state, dispatch }) => {
  const [time, setTime] = useState(0)
  const { addToast } = useToasts()
  const configToast: Options = {
    appearance: 'info',
    autoDismiss: true,
  }
  const delayMinutes = 0.5

  function check(doSent = true) {
    axios
      .post<VoteWithQuestions | ''>(GET_QUESTIONS, { code: state.code })
      .then(({ data }) => {
        if (data) {
          dispatch({ type: 'LOGIN', payload: { voteWithQuestions: data, code: state.code } })
        } else if (doSent) {
          addToast('Brak nowych wyborów. Proszę odczekać lub przycisnąć przycisk Odśwież.', configToast)
        }
      })
      .catch((reason) => {
        if (reason.response) {
          dispatch({ type: 'WAITING' })
        } else {
          dispatch({ type: 'ERROR' })
        }
      })
    setTime(0)
  }

  useInterval(() => {
    if (delayMinutes * 60 - time <= 0) {
      check(false)
    } else {
      setTime(time + 1)
    }
  }, 1000)

  return (
    <span>
      <button className='button is-info is-small is-rounded' onClick={() => check()}>
        Odśwież za {delayMinutes * 60 - time} sekund
      </button>
    </span>
  )
}
