import React, { Dispatch } from 'react'
import { ActionPayload, PageType } from '../types'

type Props = {
  pageType: PageType
  dispatch: Dispatch<ActionPayload>
}

export function ResultsLink({ pageType, dispatch }: Props) {
  if (['INIT', 'RESULTS', 'OLD_RESULTS', 'FORM'].includes(pageType)) {
    return null
  }
  return (
    <>
      <button
        type='button'
        className='button is-rounded is-outlined is-black is-normal is-fullwidth'
        style={{ width: '50%', margin: ' 25px auto' }}
        onClick={() => dispatch({ type: 'RESULTS' })}
      >
        Zobacz aktualne wyniki głosowania
      </button>
      <button
        className='button is-rounded is-outlined is-primary is-normal is-fullwidth'
        style={{ width: '50%', margin: ' 25px auto' }}
        onClick={() => {
          dispatch({ type: 'OLD_RESULTS' })
        }}
      >
        Zobacz starsze wyniki głosowania
      </button>
    </>
  )
}
