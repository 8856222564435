import React, { Dispatch, FC } from 'react'
import { Checkbox } from '../../components/Checkbox'
import { FormAction, Section } from './types'
import { Question } from '../../types'
import { NameParser } from '../NameParser'

type Props = {
  index: number
  question: Question
  options: Section
  dispatch: Dispatch<FormAction>
}

const getInputName = (idx: number, a_idx: number) => `question_${idx}_${a_idx}`

export const FormSection: FC<Props> = ({ question, options, index, dispatch }) => {
  const tooManySelected = question.max_selected < options.filter((ans) => ans.selected).length

  return (
    <fieldset className={tooManySelected ? 'error' : ''}>
      <legend>
        <NameParser title={question.name} />
        <p className='help-text'>
          Proszę wybrać maksymalnie {question.max_selected} {question.type === 'persons' ? 'osoby' : 'opcje'}.
        </p>
      </legend>
      {options.map(({ selected, value }, a_id) => (
        <div className='field' key={`${index}_${value}`}>
          <Checkbox
            key={`${index}_${a_id}`}
            id={getInputName(index, a_id)}
            label={value}
            onChange={(val) => {
              dispatch({ type: 'CHANGE', payload: { answerId: a_id, sectionId: index, checked: val! } })
            }}
            value={value}
            checked={selected}
          />
        </div>
      ))}
    </fieldset>
  )
}
