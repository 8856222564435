import { ActionPayload, State } from './types'

export function reducer(state: State, action: ActionPayload): State {
  switch (action.type) {
    case 'INIT':
      const end_date = new Date(action.payload.end_date)
      const start_date = new Date(action.payload.start_date)
      const server_date = new Date(action.payload.server_date)
      return {
        ...state,
        pageType: server_date > start_date && server_date < end_date ? 'LOGIN' : 'WAITING',
        server_date,
        group: {
          ...action.payload,
          end_date,
          start_date,
        },
      }
    case 'ERROR':
      return { ...state, pageType: action.type, errorMsg: action.msg }
    case 'FINISH':
    case 'WAITING':
    case 'LOGGED':
    case 'RESULTS':
    case 'OLD_RESULTS':
      return { ...state, pageType: action.type }
    case 'TO_LOGIN':
      return { ...state, pageType: 'LOGIN' }
    case 'LOGIN':
      const { voteWithQuestions: vote } = action.payload
      if (!vote) {
        return { ...state, pageType: 'LOGGED', code: action.payload.code }
      }
      return {
        ...state,
        pageType: 'FORM',
        code: action.payload.code,
        questions: vote.questions,
        vote: {
          id: vote.id,
          name: vote.name,
          start_date: new Date(vote.start_date),
          end_date: new Date(vote.end_date),
        },
      }
  }
  return state
}

export const initialState: State = {
  code: '',
  pageType: 'INIT',
  server_date: new Date(),
}
