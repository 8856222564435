import React, { Dispatch, useEffect, useState } from 'react'
import { ActionPayload, Stat, State } from '../types'
import axios from 'axios'
import { GET_STATS } from '../urls'
import { InitPage } from './InitPage'
import { Header } from '../components/Header'
import useInterval from 'react-useinterval'
import { Results } from './Results'

type Props = {
  state: State
  dispatch: Dispatch<ActionPayload>
}

export function ResultsPage({ state, dispatch }: Props) {
  const [localState, setState] = useState<Stat[] | null>(null)

  useEffect(() => {
    axios.get<Stat[]>(GET_STATS).then(({ data }) => {
      setState(data)
    })
  }, [])

  useInterval(() => {
    axios.get<Stat[]>(GET_STATS).then(({ data }) => {
      setState(data)
    })
  }, 3000)

  if (localState == null) {
    return <InitPage />
  }

  return (
    <>
      <div className='container-fluid'>
        <Header vote={state.group!} />
      </div>
      <div className='is-flex is-justify-content-center is-flex-direction-column is-align-items-center'>
        <section className='results'>
          {localState.length === 0 ? 'Brak zakończonych głosowań. Proszę czekać.' : <Results stats={localState} />}
          <div>
            <button
              className='button is-small is-danger is is-rounded mx-4'
              onClick={() => {
                if (state.code) {
                  dispatch({ type: 'LOGGED' })
                } else if ((state.group?.end_date || state.server_date) < state.server_date) {
                  dispatch({ type: 'WAITING' })
                } else {
                  dispatch({ type: 'TO_LOGIN' })
                }
              }}
            >
              Powrót
            </button>
          </div>
        </section>
      </div>
    </>
  )
}
